/* eslint-disable filename-rules/match */
import { useCallback, useEffect, useMemo, useState } from 'react'

import styled from '@emotion/styled'
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import Head from 'next/head'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { Header, spacing, StructuredContent } from '@cash-design-system/react'
import { useLogCashWebCdfEvent, useLogPageViewEventstream } from '@cash-web/shared-feature-analytics'
import { ContactUs, qrHashes, QrThemes, StyledStructuredContentForArticle } from '@cash-web/support/ui-components'
import { useAnalyticsContext } from '@cash-web/support/utils'
import { CustomerSupportAccessViewNodes, WebInteractClick, WebViewViewPage } from '@squareup/customer-data-events-web'

import i18nextConfig, { fallbackLng as defaultLocale } from '../../next-i18next.config'
import { getArticle } from '../../src/api'
import ArticleList from '../../src/components/ArticleList'
import HelpContent from '../../src/components/helpContent'
import Search from '../../src/components/Search'
import StatusBadge from '../../src/components/StatusBadge'
import { getPublicRuntimeConfig } from '../../src/config/nextConfig'
import { defaultRegion, supportedRegions } from '../../src/config/regions'
import useCanonicalURL from '../../src/hooks/useCanonicalUrl'
import type { Article, ArticleListing, ArticleListingWithToken } from '../../types'

const StyledArticle = styled.article`
  width: 100%;
  max-width: 48rem;
  display: flex;
  flex-direction: column;
  gap: ${spacing.l};
  margin: 0px auto;
`

const StyledStructuredContent = styled(StructuredContent)`
  ${StyledStructuredContentForArticle}
`

const { contactArticleId: contactArticleToken, deepLinkPath, frontendServiceUrl, qrPath } = getPublicRuntimeConfig()

interface ArticleProps {
  article: Article
  slugifiedPath: string
  childArticles?: ArticleListing[]
}

const ContentContainer = styled.div`
  background: ${props => props.theme.colors.background};
`

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({ locale = defaultLocale, params }) => {
  const articleSlug = params?.['article'] as string | undefined
  const articleToken = articleSlug?.match(/^(\d+)[a-zA-Z0-9-]*$/)?.[1]

  const region = params?.['region']?.toString().toLowerCase()

  if (!region || !articleToken || !Object.keys(supportedRegions).includes(region)) {
    return { notFound: true, revalidate: 3600 }
  }

  // @ts-expect-error If locale as a string isn't supported this should fail
  if (!supportedRegions[region as keyof typeof supportedRegions]?.supportedLocales?.includes(locale)) {
    // If the current region doesn't support the locale, default it
    locale = supportedRegions[region as keyof typeof supportedRegions]?.defaultLocale ?? defaultLocale
  }

  // Fetch article and translations
  return Promise.all([
    getArticle({ token: articleToken, locale, region }),
    serverSideTranslations(locale, ['common'], i18nextConfig),
  ]).then(([{ article: responseArticle, child_articles: responseChildArticles }, translations]) => {
    if (!responseArticle) {
      return { notFound: true, revalidate: 30 }
    }

    // Redirect if there's a redirect_token from the CMS
    if (responseArticle.redirect_token && articleToken != responseArticle.redirect_token) {
      return {
        redirect: { permanent: false, destination: `/${region}/${responseArticle.redirect_token}` },
        revalidate: 3600,
      }
    }

    // TODO cameronandrews remove this when getRegion and getLocale are implemented in frontend service
    // TODO cameronandrews as getRegion and getLocale return hard coded 'us' and 'en-US' this effectively limits articles to just us and en-US
    if (
      locale.toLowerCase() !== responseArticle.locale?.toLowerCase() ||
      region != responseArticle.region?.toLowerCase() ||
      !responseArticle.token
    ) {
      return { notFound: true, revalidate: 3600 }
    }

    const slugifiedPath = [articleToken, slugify(responseArticle.title)].filter(text => text).join('-') // The slugified URL only includes the title if it is set

    // Redirect if the title isn't slugified in the URL and it isn't the contact page, except at build time which isn't supported
    if (
      articleSlug != slugifiedPath &&
      responseArticle.token !== contactArticleToken &&
      process.env['NEXT_PHASE'] !== PHASE_PRODUCTION_BUILD
    ) {
      const regionToUse = region == defaultRegion ? '' : `/${region}`

      // TODO cameronandrews add locale if not default
      return { redirect: { permanent: false, destination: `${regionToUse}/${slugifiedPath}` }, revalidate: 3600 }
    }

    const article: Article = {
      title: responseArticle.title ?? '',
      content: responseArticle.body ?? '',
      token: responseArticle.token ?? '',
      metaDescription: responseArticle.meta_description,
      metaTitle: responseArticle.meta_title,
    }

    const filteredChildArticles: ArticleListing[] | undefined = responseChildArticles
      ?.map(childArticle => ({
        title: childArticle.title,
        token: childArticle.token,
        content: childArticle.summary,
        slug: childArticle.slug,
      }))
      ?.filter(childArticle => !!childArticle.token) as ArticleListing[]

    return {
      props: {
        article,
        slugifiedPath,
        childArticles: filteredChildArticles ?? null,
        ...translations,
      },
      revalidate: 3600,
    }
  })
}

const DESKTOP_QR_SIZE = 300

const ArticlePage: NextPage<ArticleProps> = ({ article, slugifiedPath, childArticles }) => {
  const { viewToken } = useAnalyticsContext()
  const canonicalUrl = useCanonicalURL(slugifiedPath)
  const { t } = useTranslation()
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const desktopQRSize = DESKTOP_QR_SIZE
  const launchChatDeepLink = deepLinkPath('support/chat?page=help')
  const logPageViewEvent = useLogPageViewEventstream()
  const logCdfEvent = useLogCashWebCdfEvent()
  const searchParams = useSearchParams()

  const onHashChanged = () => {
    const hash = window.location.hash
    setOpen(hash === qrHashes['chat'])
  }

  // Prevent the contact route from having the basePath prepended to it
  useEffect(() => {
    const originalReplaceState = window.history.replaceState

    window.history.replaceState = (state, _unused, url) => {
      // If on /contact, ignoring get parameters, and requesting to replace to /help/contact
      if (router.asPath.replace(/\?.*/, '') === '/contact' && url?.toString().replace(/\?.*/, '') === '/help/contact') {
        return undefined
      }

      return originalReplaceState.apply(window.History, [state, _unused, url])
    }

    return () => {
      window.history.replaceState = originalReplaceState
    }
  }, [router])

  const displayTypes = useMemo(() => childArticles?.map(() => 'node_tree'), [childArticles])

  const nodeTokens = useMemo(
    () => childArticles?.filter(item => 'token' in item).map(item => (item as ArticleListingWithToken).token),
    [childArticles]
  )

  const pageName = useMemo(
    () => (article.token == contactArticleToken ? 'Support Contact Page' : 'Support Article Page'),
    [article.token]
  )

  const logPageAnalytics = useCallback(() => {
    const searchText = searchParams.get('searchText')
    const totalCount = childArticles?.length || 0
    logCdfEvent(
      WebViewViewPage({
        pageName,
      })
    )
    logCdfEvent(
      CustomerSupportAccessViewNodes({
        displayTypes: displayTypes?.toString(),
        nodeTokens: nodeTokens?.toString(),
        parentToken: article.token,
        totalCount,
        trigger: 'NODE',
        viewToken: viewToken,
        searchText: searchText || '',
      })
    )
    logPageViewEvent({
      page: article.title,
    })
  }, [
    searchParams,
    childArticles?.length,
    logCdfEvent,
    pageName,
    displayTypes,
    nodeTokens,
    article.token,
    article.title,
    viewToken,
    logPageViewEvent,
  ])

  useEffect(() => {
    // check on initial page load
    if (typeof window !== 'undefined' && router.isReady) {
      logPageAnalytics()
      onHashChanged()
    }

    window?.addEventListener('hashchange', onHashChanged)

    return () => {
      window?.removeEventListener('hashchange', onHashChanged)
    }
  }, [logPageAnalytics, router.isReady])

  const logLinkClick = useCallback(
    (event: MouseEvent) => {
      const link = event.target as HTMLAnchorElement

      if (logCdfEvent && pageName) {
        logCdfEvent(
          WebInteractClick({
            pageName: pageName,
            componentName: link.href,
            componentType: link.tagName,
          })
        )
      }
    },
    [logCdfEvent, pageName]
  )

  useEffect(() => {
    // Only run client-side
    if (typeof window !== 'undefined' && article.token) {
      document.querySelectorAll<HTMLAnchorElement>('article a').forEach((link: HTMLAnchorElement) => {
        link.removeEventListener('click', logLinkClick)
        link.removeEventListener('auxclick', logLinkClick)
        link.addEventListener('click', logLinkClick, true)
        link.addEventListener('auxclick', logLinkClick, true)
      })
    }
  }, [article.token, logLinkClick])

  const closeQrModal = useCallback(() => {
    // Remove #chat from the URL
    if (router.asPath.startsWith('/contact')) {
      window?.history.replaceState(null, '', '/contact') // We don't want the basePath to be added back in on the /contact page, which router does
    } else {
      router.replace(
        {
          hash: '',
          pathname: router.pathname,
          query: { region: defaultRegion, article: slugifiedPath, ...router.query },
        },
        router.asPath.replace(new RegExp(`${qrHashes['chat']}$`), ''),
        { shallow: true }
      )
    }

    setOpen(false)
  }, [router, slugifiedPath])

  return (
    <>
      <Head>
        <title>{article.metaTitle ?? article.title}</title>
        <meta name="description" content={article.metaDescription} />
        <link
          rel="canonical"
          href={article.token == contactArticleToken ? `${frontendServiceUrl}/contact` : canonicalUrl}
        />
      </Head>
      <ContentContainer>
        <HelpContent>
          <section>
            <StatusBadge />
            <Search invert showMoreResults />
          </section>
          <section>
            <StyledArticle>
              <Header data-testid={article.token} color="label" variant="largeLabel" as="h1">
                {article.title}
              </Header>
              <StyledStructuredContent dangerouslySetInnerHTML={{ __html: article.content }}></StyledStructuredContent>
            </StyledArticle>
          </section>
        </HelpContent>
      </ContentContainer>
      <HelpContent>
        {childArticles?.length ? (
          <section>
            <ArticleList
              title={t('Related', {
                context: 'Subheading on the help article page with a list of articles related to the current article',
              })}
              data={childArticles}
              trigger="NODE"
              analyticsType="NODE_TREE"
            />
          </section>
        ) : undefined}
        <section>
          <ContactUs
            mobileChatDeepLink={launchChatDeepLink}
            desktopQRPath={qrPath('launch/support/chat')}
            desktopQRSize={desktopQRSize}
            desktopQRTheme={QrThemes.LIGHT}
            open={open}
            onCloseQrModal={closeQrModal}
            cdfTrigger={{
              viewToken: viewToken,
              trigger: 'NODE',
              triggerNodeToken: article.token,
            }}
            t={t}
          />
        </section>
      </HelpContent>
    </>
  )
}

/** Converts text into a slug */
export const slugify = (text?: string) =>
  text
    ?.replaceAll(/[^a-z0-9 ]/gi, '')
    .trim()
    .replaceAll(' ', '-')
    .toLowerCase() ?? ''

export default ArticlePage
