import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { getLinkStyle } from '../../styles'

const spacedElements = 'p, ol, ul, h1, h2, h3, h4, h5, h6'

export const th = (theme: Theme) => css`
  color: ${theme.colors.secondaryLabel};
  background: ${theme.colors.secondaryBackground};
  font-weight: ${theme.weights.medium};
`

const StructuredContent = styled.div`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.secondaryLabel};
  margin-top: ${props => props.theme.spacing.m};
  margin-bottom: ${props => props.theme.spacing.m};

  a[href] {
    ${props => getLinkStyle(props.theme)};
  }

  img {
    max-width: 100%;
  }

  ol,
  ul {
    padding-left: ${({ theme }) => theme.spacing.l};
    list-style-position: outside;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  *:is(${spacedElements}) {
    margin: ${({ theme: { spacing } }) => `${spacing.m} ${spacing.empty}`};
  }

  table *:is(${spacedElements}):first-of-type {
    margin-top: 0;
  }

  table *:is(${spacedElements}):last-child {
    margin-bottom: 0;
  }

  th,
  td {
    text-align: left;
    vertical-align: top;
    padding: ${({ theme }) => theme.spacing.xs};
    border: 1px solid ${props => props.theme.colors.hairline};
  }

  th {
    ${props => th(props.theme)};
  }
`

export default StructuredContent
