import { useCallback } from 'react'

import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import { DocumentFillIcon, LinkOutIcon } from '@arcade/web/icons/react'
import { Header, spacing } from '@cash-design-system/react'
import { useLogCashWebCdfEvent } from '@cash-web/shared-feature-analytics'
import { CardItem, CardList, LinkAndAnchorStyles } from '@cash-web/support/ui-components'
import { useAnalyticsContext } from '@cash-web/support/utils'
import type {
  CustomerSupportAccessOpenNodeSelectedType,
  CustomerSupportAccessOpenNodeTrigger,
} from '@squareup/customer-data-events-web'
import { CustomerSupportAccessOpenNode, WebInteractClick } from '@squareup/customer-data-events-web'

import { fallbackLng as defaultLocale } from '../../../next-i18next.config'
import type { ArticleListing } from '../../../types'
import Link from '../../components/RegionalisedLink'

const StyledHeader = styled(Header)`
  margin-bottom: ${spacing.m};
`

const StyledAnchor = styled(Link)`
  ${LinkAndAnchorStyles}
`

interface ArticleListProps {
  tabletColumns?: 1 | 2
  trigger: CustomerSupportAccessOpenNodeTrigger
  data: ArticleListing[]
  title?: string
  analyticsType?: CustomerSupportAccessOpenNodeSelectedType
}

const ArticleList = ({ data, tabletColumns = 1, title, trigger, analyticsType = 'SUGGESTED' }: ArticleListProps) => {
  const { locale } = useRouter()
  const logCdfEvent = useLogCashWebCdfEvent()
  const { viewToken } = useAnalyticsContext()

  const trackEvent = useCallback(
    (article: ArticleListing & { index: number }) => {
      logCdfEvent(
        WebInteractClick({
          pageName: article.title,
          componentName: 'A',
          componentType: 'LINK_CLICK',
        })
      )

      if ('token' in article) {
        logCdfEvent(
          CustomerSupportAccessOpenNode({
            trigger: trigger,
            viewToken: viewToken,
            selectedType: analyticsType,
            selectedTypeCount: data.length,
            selectedTypeIndex: article.index,
            nodeToken: article.token,
          })
        )
      }
    },
    [data, logCdfEvent, analyticsType, viewToken, trigger]
  )

  return (
    <>
      {title ? (
        <StyledHeader variant="tabTitle" color="secondaryLabel" as="h2">
          {title}
        </StyledHeader>
      ) : undefined}
      <CardList
        divider
        columns={{ mobile: 1, tablet: tabletColumns }}
        data={data}
        render={article => (
          <StyledAnchor
            href={'token' in article ? `/${article.token}-${article.slug}` : article.url}
            target={'url' in article ? '_blank' : undefined}
            locale={locale == defaultLocale ? false : undefined}
            onClick={() => trackEvent(article)}
          >
            <CardItem
              title={article.title ?? ''}
              isClickable
              icon={'token' in article ? <DocumentFillIcon /> : <LinkOutIcon />}
            />
          </StyledAnchor>
        )}
      />
    </>
  )
}

export default ArticleList
